




































































































































































































import Vue from "vue";
import {
  ContactData,
  ResponseListContactData,
} from "@/models/interface/contact.interface";
import { contactServices } from "@/services/contact.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { debounceProcess } from "@/helpers/debounce";
import { ResponsePagination } from "@/models/interface/common.interface";
import { mapGetters } from "vuex";
export default Vue.extend({
  name: "ListContact",
  data() {
    this.reponseSearchInput = debounceProcess(this.reponseSearchInput, 200);
    return {
      totalElements: 0 as number,
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      search: "customer~true" as string,
      direction: "" as string,
      activeTabPane: "Customer",
      columnTabPane: [
        {
          key: "Customer",
          label: "lbl_customer",
        },
        {
          key: "Supplier",
          label: "lbl_supplier"
        },
        {
          key: "Employee",
          label: "lbl_employee"
        }
      ],
      dataSource: [] as ContactData[],
      loadingTable: false as boolean,
      loadingDownload: false as boolean,
    };
  },
  computed: {
    ...mapGetters({
      getUserPrivileges: "authStore/GET_USER_PRIVILEGES"
    }),
    isTabCustomer(): boolean {
      return this.activeTabPane === "Customer";
    },
    isTabSupplier(): boolean {
      return this.activeTabPane === "Supplier";
    },
    isTabEmployee(): boolean {
      return this.activeTabPane === "Employee";
    },
    hasPrivilegeContactDataUpdate(): boolean {
      return this.getUserPrivileges.find(x => x.key === "contact-data" && x.privilege.update && x.privilege.create);
    },
  },
  created() {
    this.getListContact();
  },
  methods: {
    findCode(): string {
      if (this.isTabCustomer) return "customerNumber";
      if (this.isTabSupplier) return "supplierNumber";
      if (this.isTabEmployee) return "employeeNumber";
      return "";
    },
    handleDownload() {
      let params: RequestQueryParamsModel = {
        // limit: this.limit,
        // page: this.page - 1,
        search: `${this.activeTabPane.toLocaleLowerCase()}~true`,
      };
      this.loadingDownload = true;
      contactServices
        .downloadReportContact(params)
        .then((dataBlob) => {
          if (dataBlob) {
            const url = window.URL.createObjectURL(new Blob([dataBlob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "report_contact.xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
        })
        .finally(() => (this.loadingDownload = false));
    },
    handleEdit(record) {
      this.$router.push(`/contact/update/${record.id}`);
    },
    handleChangeMenu(value) {
      this.page = 1;
      this.activeTabPane = value;
      this.search = `${this.activeTabPane.toLocaleLowerCase()}~true`;
      this.getListContact();
    },
    reponseSearchInput(response): void {
      if (response)
        this.search = `${this.activeTabPane.toLocaleLowerCase()}~true_AND_firstName~*${response}*_OR_lastName~*${response}*_OR_phoneNumber~*${response}*_OR_email~*${response}*_OR_${this.activeTabPane.toLocaleLowerCase()}Number~*${response}*`;
      else this.search = `${this.activeTabPane.toLocaleLowerCase()}~true`;
      this.getListContact();
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListContact();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListContact();
    },
    onSorterChange(response): void {
      if (response.sort.order) {
        this.direction = response.sort.order === "ascend" ? "asc" : "desc";
        this.sort = `${response.sort.field}:${this.direction}`;
      } else {
        this.resetFilter(false);
      }
      this.getListContact();
    },
    resetFilter(getlist): void {
      this.direction = "";
      this.search = `${this.activeTabPane.toLocaleLowerCase()}~true`;
      if (getlist) this.getListContact();
    },
    getListContact() {
      let params = {
        limit: this.limit,
        page: this.page - 1,
        sorts: "firstName:asc"
      } as RequestQueryParamsModel;
      if (this.search) params.search = this.search;
      if (this.direction) params.sorts = this.sort;
      this.dataSource = [];
      this.loadingTable = true;
      contactServices
        .listContactData(params)
        .then((res: ResponseListContactData) => {
          this.dataSource = res.data;
          this.totalElements = res.totalElements;
        })
        .finally(() => (this.loadingTable = false));
    },
    createNew() {
      this.$router.push("/contact/create");
    },
  },
});
